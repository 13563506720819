import type { Router } from 'vue-router'

/**
 * setup client and mount app, any external imports should be code split via dynamic imports
 * this ensures that we optmize setup code for main entry into different chunks that can be
 * long term chached
 */
export async function client(): Promise<void> {
  // setup config so that from this point onwards we can safely use
  // the runtime config
  await import('@attest/config').then(config => config.initialise())

  // BUG in vite where the import().then syntax doesn't correctly code split
  const { router, app } = await (await import('@attest/_/setup/app')).setupApp()

  // Setup feature switch client before progressing so from this point on
  // we can stably use feature switches.
  await import('@attest/feature-switch').then(async fs => {
    const { useUserStore } = await import('@attest/user')
    const userStore = useUserStore()

    await fs.useSetupFeatureSwitchClient(userStore)
  })

  await import('@attest/a3').then(a3 => a3.useSetupA3AxiosAuthenticator())

  // setup initial authentication of user
  await import('@attest/a3').then(async a3 => {
    const a3Store = a3.useA3Store()
    const { useUserStore } = await import('@attest/user')
    const userStore = useUserStore()

    if (a3Store.isA3Available()) {
      if (!a3Store.isAuthenticated) {
        await a3Store.authenticate()
      }
      if (a3Store.isAuthenticated) {
        await userStore.getUserAndAdditionalInfo()
      }
    } else {
      await userStore.authenticate()
      if (userStore.isAuthenticated) {
        await userStore.getUserAndAdditionalInfo()
      }
    }
  })

  // setup router hooks
  await import('./setup/router').then(async r => r.useSetupRouterHooks(router))

  // setup essential application pre-resiqites
  await Promise.all([
    import('./setup/tracking').then(tracking => tracking.setupTracking(router)),
    import('./setup/progress-bar').then(progressBar => progressBar.useSetupProgressBar(router)),
    setIntitialTimeZoneId(),
    setupHighcharts().then(highcharts => exposeAppInternals(router, highcharts)),
    import('@attest/_api').then(api =>
      api.interceptResponse(
        response => response,
        async error => {
          const { useA3Store } = await import('@attest/a3')
          const a3Store = useA3Store()
          if (a3Store.isA3Available()) {
            throw error
          }

          if (error.response && error.response.status === 401) {
            await navigateToLogin(router)
          }

          throw error
        },
      ),
    ),
  ])

  // initialise router and mount app after initial resolving
  app.use(router)
  await router.isReady()
  app.mount('#app')

  await removeLoadingPlaceholder()
}

async function navigateToLogin(router: Router): Promise<void> {
  const { ROUTES } = await import('@attest/router')
  if (router.currentRoute.value.name === ROUTES.LOGIN.name) return
  window.location.href = router.resolve({
    name: ROUTES.LOGIN.name,
    query: { redirect: router.currentRoute.value.fullPath },
  }).href
}

// TODO: optimize this to only run on the editor as this is expensive to preform and we don't
// need to preload the timezones unless we are on editor
async function setIntitialTimeZoneId(): Promise<void> {
  const { Thyme, ThymeZone } = await import('@attest/thyme')
  const { useEditorSurveyStore } = await import('@attest/editor-survey')
  const names = ThymeZone.getIANANames()
  const editorSurveyStore = useEditorSurveyStore()
  const timeZoneId = editorSurveyStore.timeZoneId ?? Thyme.local().zone.name

  editorSurveyStore.timeZoneId = names.includes(timeZoneId) ? timeZoneId : 'Europe/London'
}

function exposeAppInternals(router: Router, highcharts: any): void {
  if (!('Cypress' in window)) return
  ;(window as any).__INTERNALS__ = {
    router,
    Highcharts: highcharts,
  }
}

async function removeLoadingPlaceholder(): Promise<void> {
  const loading = document.getElementById('loading')
  if (!loading) return
  loading.dataset.loaded = 'true'
  loading.addEventListener('transitionend', () => {
    loading?.remove()
    document.getElementById('loading-style')?.remove()
  })
}

// TODO; optimize to only run on results pages
// as we don't need to initiate highcharts unless on results routes
async function setupHighcharts() {
  const Highcharts = (await import('highcharts')).default
  const highchartsAccessibility = (await import('highcharts/modules/accessibility')).default
  highchartsAccessibility(Highcharts)

  return Highcharts
}
